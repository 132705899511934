export const EventsData = [
    {
        "id" : 1,
        "eventDate" : "Jan 18, 2024",
        "eventCost" : "Free",
        "eventName" : "Technical Career Fair 2024",
        "eventDetails" : "Check out the career fair that are happening at University of British Columbia this month! *Please note that attendance may be restricted to current students or alumni of the host university.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://ubccsss.org/tcf/",
        "eventImage" : "/images/events/1704301293103.jpeg",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 2,
        "eventDate" : "Jan 18, 2024",
        "eventName" : "Python Essentials Part 1",
        "eventDetails" : "Toronto Public Library's Python Essentials Part 1 Learning Circle starts on January 18th! This is a free event, hosted in person at a Toronto Public Library branch. Interested?",
        "buttonName" : "Learn More",
        "buttonLink" : "https://events.tplcs.ca/3635",
        "eventImage" : "/images/events/1704906101330.jpeg",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 3,
        "eventDate" : "Jan 24, 2024",
        "eventCost" : "Free",
        "eventName" : "Winter 2024 Engineering & Technology Fair",
        "eventDetails" : "Check out the career fair that are happening at Queen's University this month! *Please note that attendance may be restricted to current students or alumni of the host university.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://careers.queensu.ca/events-workshops/career/engineering-technology-day",
        "eventImage" : "/images/events/Event3.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 4,
        "eventDate" : "Jan 30, 2024",
        "eventCost" : "Free",
        "eventName" : "Winter Career Fair",
        "eventDetails" : "Check out the career fair that are happening at University of Calgary this month! *Please note that attendance may be restricted to current students or alumni of the host university.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.ucalgary.ca/career-personal-development/events/winter-career-fair",
        "eventImage" : "/images/events/Event4.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 5,
        "eventDate" : "Feb 21, 2024",
        "eventEndDate" : "Feb 23, 2024",
        "eventCost" : "25",
        "eventName" : "Quantum Days 2024",
        "eventDetails" : "Deep Tech Canada is hosting Quantum Days 2024 from February 21-23. Connect with other students, researchers, industry-leaders and more! Tickets start at $25 for students.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://2024.quantumdays.ca/",
        "eventImage" : "/images/events/Feb 7 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 6,
        "eventDate" : "Feb 15, 2024",
        "eventEndDate" : "Feb 21, 2024",
        "eventCost" : "Free",
        "eventName" : "Break Into Tech Workshops",
        "eventDetails" : "Northeastern University is hosting free Break Into Tech workshops on February 15th and 21st. No programming experience is required!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://graduate.northeastern.edu/event/break-into-tech-vancouver-4/",
        "eventImage" : "/images/events/Feb 14 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 7,
        "eventDate" : "Mar 09, 2024",
        "eventEndDate" : "Mar 10, 2024",
        "eventCost" : "",
        "eventName" : "cmd-f 2024 Hackathon",
        "eventDetails" : "University of British Columbia's cmd-f 2024 Hackathon celebrates underrepresented genders in tech, and will take place on March 9-10, 2024! Interested?",
        "buttonName" : "Learn More",
        "buttonLink" : "https://cmd-f.nwplus.io/",
        "eventImage" : "/images/events/Feb 21 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 8,
        "eventDate" : "Feb 10, 2024",
        "eventEndDate" : "Feb 24, 2024",
        "eventCost" : "60",
        "eventName" : "AI Mastery Series - Unleash the Power of AI using Cloud Technologies!",
        "eventDetails" : "Embark on a transformative journey into the world of Artificial Intelligence! Our AI Mastery Series is crafted for individuals passionate about data, technology, and the limitless possibilities of AI. Join us on Feb 10, 17, and 24.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/ai-mastery-series-unleash-the-power-of-ai-using-cloud-technologies-tickets-770891345767?aff=ebdsoporgprofile",
        "eventImage" : "/images/events/ai_mastery_series.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 9,
        "eventDate" : "Every 3rd Sat",
        "eventEndDate" : "Dec 21, 2024",
        "eventCost" : "Free",
        "eventName" : "Unlock Data Science Career Certifications | Data Science,ML,AI Info Session",
        "eventDetails" : "Are you ready to embark on a journey to become a certified AI professional? Join us for our Data Science Online Info session event every month on 3rd Saturday!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/unlock-data-science-career-certifications-data-sciencemlai-info-session-tickets-743887165537?aff=ebdsoporgprofile",
        "eventImage" : "/images/events/unlock_data_science_career_certifications_v2.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 10,
        "eventDate" : "Mar 28, 2024",
        "eventEndDate" : "Mar 28, 2024",
        "eventCost" : "Free",
        "eventName" : "Coding with Python IV",
        "eventDetails" : "Have a basic understanding of Python, but want to learn more? Check out Toronto Public Library's free online Coding with Python IV class on March 28!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/coding-with-python-iv-tickets-791522554267?aff=oddtdtcreator",
        "eventImage" : "/images/events/March 13 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 11,
        "eventDate" : "Mar 26, 2024",
        "eventEndDate" : "Mar 26, 2024",
        "eventCost" : "Free",
        "eventName" : "Data Day 10.0: The Next Decade for Data Science",
        "eventDetails" : "Carleton University's 10th annual Data Day is coming up on March 26th! This free event will include multiple data-related sessions, as well as networking opportunities!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://science.carleton.ca/data-day/#anchor-10",
        "eventImage" : "/images/events/March 20 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 12,
        "eventDate" : "Apr 9, 2024",
        "eventEndDate" : "Apr 9, 2024",
        "eventCost" : "Free",
        "eventName" : "Technata Career Fair 2024",
        "eventDetails" : "Looking for a job in tech? Discover Technata has a career fair coming up on April 9th in Kanata, Ontario!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.discovertechnata.com/career-fair/",
        "eventImage" : "/images/events/March 27 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 13,
        "eventDate" : "May 3, 2024",
        "eventEndDate" : "May 5, 2024",
        "eventCost" : "Free",
        "eventName" : "GDSC Hacks 2024",
        "eventDetails" : "Check out GDSC Hacks 2024, a free hackathon that is occurring at the University of Guelph from May 3-5! Applications close April 7th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.gdschacks.com/",
        "eventImage" : "/images/events/April 3 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 14,
        "eventDate" : "Apr 12, 2024",
        "eventEndDate" : "Apr 12, 2024",
        "eventCost" : "17.31",
        "eventName" : "2024 Vancouver Women in Data Science Conference",
        "eventDetails" : "On Friday April 12, the 2024 Vancouver Women in Data Science Conference is taking place in-person in Vancouver, BC!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/2024-vancouver-women-in-data-science-conference-tickets-781509525027",
        "eventImage" : "/images/events/April 10 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 15,
        "eventDate" : "Apr 26, 2024",
        "eventEndDate" : "Apr 28, 2024",
        "eventCost" : "25",
        "eventName" : "UHACK 2024",
        "eventDetails" : "Hackathon alert! Check out UHACK 2024, taking place from April 26-28 in Gatineau!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/billets-uhack-2024-hackathon-771779211397?aff=erelexpmlt",
        "eventImage" : "/images/events/April 17 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 16,
        "eventDate" : "May 16, 2024",
        "eventEndDate" : "May 16, 2024",
        "eventCost" : "Free",
        "eventName" : "Women in Tech Toronto - OutGeekWomen",
        "eventDetails" : "Check out the Women in Tech Toronto Event, happening on May 16 in Toronto!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/women-in-tech-toronto-outgeekwomen-tickets-853092371247?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/April 24 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 17,
        "eventDate" : "May 16, 2024",
        "eventEndDate" : "May 16, 2024",
        "eventCost" : "Free",
        "eventName" : "Google Smartphone Decimeter Challenge 2023",
        "eventDetails" : "Check out this Kaggle Community Competition, hosted by Google! It's free to sign up- just don't miss the entry deadline on May 16!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.kaggle.com/competitions/smartphone-decimeter-2023",
        "eventImage" : "/images/events/May 1 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 18,
        "eventDate" : "Jun 1, 2024",
        "eventEndDate" : "Jun 29, 2024",
        "eventCost" : "60",
        "eventName" : "Data Mastery Series - Unleash Your Insights, Transform Your Industry!",
        "eventDetails" : "Unlock the Power of Data: Master Analytics tools, Visualize insights, and transform industries with our immersive workshop series.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://ow.ly/xisa50RyaER",
        "eventImage" : "/images/events/data_mastery_series.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 19,
        "eventDate" : "May 30, 2024",
        "eventEndDate" : "May 30, 2024",
        "eventCost" : "Free",
        "eventName" : "TechConnect for Newcomer Talent",
        "eventDetails" : "Check out this TechConnect for Newcomer Talent event happening on May 30! It's free to sign up!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/techconnect-for-newcomer-talent-tickets-884272782697?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/May 22 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 20,
        "eventDate" : "Jun 15, 2024",
        "eventEndDate" : "Jun 15, 2024",
        "eventCost" : "Free",
        "eventName" : "Introduction to SQL and Cybersecurity - Calgary",
        "eventDetails" : "Check out this workshop for an Introduction to SQL and Cybersecurity happening on Saturday, June 15. Tickets are free, so sign up today!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/introduction-to-sql-and-cybersecurity-calgary-tickets-904886769687?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/June 5 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 21,
        "eventDate" : "Jun 13, 2024",
        "eventEndDate" : "Jun 13, 2024",
        "eventCost" : "Free",
        "eventName" : "Coffee & Code Meetup: AI & Machine Learning Demystified - Edmonton",
        "eventDetails" : "Check out this Coffee & Code Meetup to learn more about AI and Machine Learning, happening on June 13 in Edmonton! Tickets are free, so sign up today!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/coffee-code-meetup-ai-machine-learning-demystified-edmonton-tickets-894646631157?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/June 12 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 22,
        "eventDate" : "Jun 25, 2024",
        "eventEndDate" : "Jun 25, 2024",
        "eventCost" : "Free",
        "eventName" : "VAN Tech Morning: Careers & Coffee",
        "eventDetails" : "Check out this event where BrainStation and industry leaders discuss more about career paths in technology, happening on June 25 in Vancouver! Tickets are free, so sign up today!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/van-tech-morning-careers-coffee-tickets-908278544577?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/June 19 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 23,
        "eventDate" : "Jun 29, 2024",
        "eventEndDate" : "Jun 29, 2024",
        "eventCost" : "Free",
        "eventName" : "Managing Data with SQL - Edmonton",
        "eventDetails" : "Check out this workshop for learning how to manage data with SQL happening on June 29 in Edmonton! Tickets are free, so sign up today!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/managing-data-with-sql-edmonton-tickets-894727633437?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/June 26 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 24,
        "eventDate" : "Jun 27, 2024",
        "eventEndDate" : "Jun 27, 2024",
        "eventCost" : "Free",
        "eventName" : "Calgary Women in Tech Coffee with Toast",
        "eventDetails" : "Check out the Women in Tech Coffee Event with Toast, happening on June 27 in Calgary! Tickets are free, so sign up today!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/calgary-women-in-tech-coffee-with-toast-tickets-818694817237?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/May 29 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 25,
        "eventDate" : "Jul 3, 2024",
        "eventEndDate" : "Jul 3, 2024",
        "eventCost" : "Free",
        "eventName" : "Artificial Intelligence Lecture Series",
        "eventDetails" : "The Balsillie School of International Affairs and Ken Seiling Waterloo Region Museum are hosting an Artificial Intelligence Lecture Series on July 9th in Kitchener, ON. Tickets are free for students, so sign up here!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/artificial-intelligence-lecture-series-tickets-878308222537?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/July 3 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 26,
        "eventDate" : "Jul 13, 2024",
        "eventEndDate" : "Jul 13, 2024",
        "eventCost" : "21.55",
        "eventName" : "TMLS Hackathon",
        "eventDetails" : "The Toronto Machine Learning Society is hosting a hackathon on July 13 in Toronto! This hackathon is centred around fine-tuning open source LLMs for fun and profit, and tickets start at $21.55.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/tmls-hackathon-fine-tuning-open-source-llms-for-fun-and-profit-tickets-925431710157?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/July 10 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 27,
        "eventDate" : "Jul 23, 2024",
        "eventEndDate" : "Jul 23, 2024",
        "eventCost" : "Free",
        "eventName" : "HackerX - Vancouver",
        "eventDetails" : "Are you a developer? Check out this speed-dating-style recruiting event where you can meet up to 15-20 companies! The event takes place in Vancouver on July 23rd, and tickets are free for developers.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/hackerx-vancouver-full-stack-0723-onsite-tickets-928583737957?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/July 17 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 28,
        "eventDate" : "Jul 27, 2024",
        "eventEndDate" : "Jul 27, 2024",
        "eventCost" : "27.96",
        "eventName" : "STRIPE Coding Competition - Langley Division",
        "eventDetails" : "Zebra Robotics Langley is hosting a coding competition on July 27th in Langley, BC! This competition is open to all skill levels, and tickets start at $27.96.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/stripe-coding-competition-langley-division-tournament-tickets-903835194397?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/July 24 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 29,
        "eventDate" : "Aug 3, 2024",
        "eventEndDate" : "Aug 3, 2024",
        "eventCost" : "22.63",
        "eventName" : "Choose Your Own Deventure",
        "eventDetails" : "Quiver Games is hosting hands-on workshops in Game Dev at Northeastern University on August 3rd! General admission tickets are $27.96.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/choose-your-own-deventure-a-game-dev-intensive-2024-registration-879205987777?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/July 31 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 30,
        "eventDate" : "Aug 13, 2024",
        "eventEndDate" : "Aug 13, 2024",
        "eventCost" : "Free",
        "eventName" : "TO Tech Morning: Careers and Coffee",
        "eventDetails" : "BrainStation is hosting a Careers and Coffee Tech Morning in Toronto on August 13th! Tickets are free!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/to-tech-morning-careers-coffee-i-brainstation-tickets-934518258267?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/August 7 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 31,
        "eventDate" : "Aug 16, 2024",
        "eventEndDate" : "Aug 16, 2024",
        "eventCost" : "Free",
        "eventName" : "Computer Science and Mechanical Engineering Technology Symposium 2024",
        "eventDetails" : "Camosun College is hosting a free symposium in Victoria on August 16th! Reserve your tickets today!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/computer-science-and-mechanical-engineering-technology-symposium-2024-tickets-933566310967?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/August 14 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 32,
        "eventDate" : "Sep 24, 2024",
        "eventEndDate" : "Sep 24, 2024",
        "eventCost" : "",
        "eventName" : "NVIDIA Holoscan Bootcamp",
        "eventDetails" : "The NVIDIA Holoscan Virtual Bootcamp is occurring online on September 24th! The deadline to apply is September 4th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.openhackathons.org/s/siteevent/a0CUP00000haVXi2AM/se000351",
        "eventImage" : "/images/events/August 21 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 33,
        "eventDate" : "Sep 12, 2024",
        "eventEndDate" : "Sep 12, 2024",
        "eventCost" : "Free",
        "eventName" : "Webinar: Scale applications, analytics, and gen AI with databases on any cloud",
        "eventDetails" : "IBM is hosting a free, online webinar on September 12th!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://community.ibm.com/community/user/datamanagement/events/event-description?CalendarEventKey=690605e0-c5f2-4294-9c26-019055f3d4e8&CommunityKey=5f34857e-d55b-404b-9806-a571c485c1ff&Home=%2Fcommunity%2Fuser%2Fdatamanagement%2Fevents%2Fevent-description",
        "eventImage" : "/images/events/August 28 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 34,
        "eventDate" : "Sep 10, 2024",
        "eventEndDate" : "Sep 10, 2024",
        "eventCost" : "Free",
        "eventName" : "Vancouver Tech Social by BrainStation",
        "eventDetails" : "BrainStation is hosting an in-person Vancouver Tech Social on September 10! Tickets are free!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/vancouver-tech-social-i-brainstation-tickets-951623891677?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/Sept 4 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 35,
        "eventDate" : "Sep 19, 2024",
        "eventEndDate" : "Sep 19, 2024",
        "eventCost" : "Free",
        "eventName" : "Tech Night by APEX Southeast Alberta",
        "eventDetails" : "APEX Southeast Alberta is hosting a Tech Night on September 19th at Medicine Hat College! Tickets are free!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/tech-night-september-19-tickets-944391539517?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/Sept 10 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 36,
        "eventDate" : "Sep 25, 2024",
        "eventEndDate" : "Sep 25, 2024",
        "eventCost" : "Free",
        "eventName" : "Here We Code: Tech Career Fair by Dalhousie's Faculty of Computer Science",
        "eventDetails" : "Dalhousie's Faculty of Computer Science is hosting Here We Code, a Tech Career Fair, on September 25th in Halifax! Tickets are free!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/here-we-code-tech-career-fair-tickets-945126708427?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/Sept 18 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 37,
        "eventDate" : "Oct 09, 2024",
        "eventEndDate" : "Oct 09, 2024",
        "eventCost" : "",
        "eventName" : "Fall Career & Networking Fair by Carleton University",
        "eventDetails" : "On October 9th, Carleton University's Fall Career & Networking Fair is occurring in Ottawa! Tickets are restricted to Carleton University students and alumni.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://carleton.ca/career/cu-events/fall-career-and-networking-fair/",
        "eventImage" : "/images/events/Sept 25 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 38,
        "eventDate" : "Oct 10, 2024",
        "eventEndDate" : "Oct 10, 2024",
        "eventCost" : "18.81",
        "eventName" : "AI & Tech Speed Networking Toronto by Startup Valley",
        "eventDetails" : "Engage with leading experts and founders in Tech & Business, and top investors to gain deep insights and valuable networking. Hosted on October 10th with tickets starting at $18.81.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://ow.ly/878Q50TBwgA",
        "eventImage" : "/images/events/Oct 3 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 39,
        "eventDate" : "Oct 18, 2024",
        "eventEndDate" : "Oct 18, 2024",
        "eventCost" : "22.63",
        "eventName" : "Vancouver Tech & Finance Networking Event by SUM OF FIVE",
        "eventDetails" : "If you're in Vancouver, don't miss the SUM OF FIVE Networking Event! This is your chance to connect with professionals, grow your network, and advance your career in a meaningful way on October 18th. Tickets go from $22.63.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://ow.ly/VF0G50TJknl",
        "eventImage" : "/images/events/Oct 9 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 40,
        "eventDate" : "Oct 29, 2024",
        "eventEndDate" : "Oct 29, 2024",
        "eventCost" : "Free",
        "eventName" : "Tech Industry Job Fair",
        "eventDetails" : "Looking for a job in Tech in New Brunswick? Head to the free Tech Job Fair hosted by the  Tech Impact and Job Developers Network at the Delta Hotel in Saint John on October 29th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/tech-industry-job-fair-tickets-1030163866857?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/October 18 Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 41,
        "eventDate" : "Jan 18, 2025",
        "eventEndDate" : "Jan 18, 2025",
        "eventCost" : "Free",
        "eventName" : "Intro to AI: ChatGPT",
        "eventDetails" : "Want to learn about ChatGPT? Toronto Public Library is hosting a free Intro to AI course on January 18 that will provide insights into AI and ChatGPT!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/intro-to-ai-chatgpt-tickets-1125472471459?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/Jan 15 Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 42,
        "eventDate" : "Jan 23, 2025",
        "eventEndDate" : "Jan 23, 2025",
        "eventCost" : "Free",
        "eventName" : "Edmonton Career Fair and Training Expo Canada",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in Edmonton at the Edmonton Convention Centre on January 23rd.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/edmonton-career-fair-and-training-expo-canada-january-23-2025-tickets-995448271577?aff=oddtdtcreator",
        "eventImage" : "/images/events/Jan 20a Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 43,
        "eventDate" : "Jan 24, 2025",
        "eventEndDate" : "Jan 24, 2025",
        "eventCost" : "Free",
        "eventName" : "LinkedIn: How It Can Help You Get a Job",
        "eventDetails" : "Interested in learning how to improve your LinkedIn profile? On January 24, Toronto Public Library will be hosting an info session where you can learn how LinkedIn is valuable in job searching and tips to optimize your profile!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/linkedin-how-it-can-help-you-get-a-job-tickets-1106767544519?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/Jan 20b Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 44,
        "eventDate" : "Jan 30, 2025",
        "eventEndDate" : "Jan 30, 2025",
        "eventCost" : "Free",
        "eventName" : "Calgary Career Fair and Training Expo Canada",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in Calgary at the BMO Centre on January 30th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/calgary-career-fair-and-training-expo-canada-january-30-2025-tickets-995377309327?aff=oddtdtcreator",
        "eventImage" : "/images/events/Jan 27a Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 45,
        "eventDate" : "Jan 30, 2025",
        "eventEndDate" : "Jan 30, 2025",
        "eventCost" : "Free",
        "eventName" : "Securing Employment: Interviewing",
        "eventDetails" : "Are you looking for a job? Join ACCES Employment at the Toronto Public Library to learn about interviews!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/securing-employment-interviewing-tickets-1088786161719?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/Jan 27b Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 46,
        "eventDate" : "Jan 31, 2025",
        "eventEndDate" : "Jan 31, 2025",
        "eventCost" : "Free",
        "eventName" : "CrossTALK: Cross-Training in AI and Laboratory Knowledge for Drug Discovery",
        "eventDetails" : "Want to learn about integrating artificial intelligence with laboratory practices in drug discovery? The Data Sciences Institute is hosting CrossTALK on January 31 at the University of Toronto.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/crosstalk-cross-training-in-ai-and-laboratory-knowledge-for-drug-discovery-tickets-1104923007459?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/Jan 27c Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 47,
        "eventDate" : "Mar 11, 2025",
        "eventEndDate" : "Mar 11, 2025",
        "eventCost" : "Free",
        "eventName" : "CareerFair Canada Red Deer",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in Red Deer at Harvest Centre on March 11th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/red-deer-career-fair-and-training-expo-canada-march-11-2025-tickets-1057157980979?aff=oddtdtcreator",
        "eventImage" : "/images/events/Mar4a - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 48,
        "eventDate" : "Mar 13, 2025",
        "eventEndDate" : "Mar 13, 2025",
        "eventCost" : "Free",
        "eventName" : "CareerFair Canada Edmonton",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in Edmonton at the Edmonton Convention Centre on March 13th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/edmonton-career-fair-and-training-expo-canada-march-13-2025-tickets-995450237457?aff=oddtdtcreator",
        "eventImage" : "/images/events/Mar4b - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 49,
        "eventDate" : "Mar 20, 2025",
        "eventEndDate" : "Mar 20, 2025",
        "eventCost" : "Free",
        "eventName" : "CareerFair Canada Calgary",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in Calgary at BMO Centre on March 20th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/calgary-career-fair-and-training-expo-canada-march-20-2025-tickets-995402243907?aff=oddtdtcreator",
        "eventImage" : "/images/events/Mar11 - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 50,
        "eventDate" : "Mar 26, 2025",
        "eventEndDate" : "Mar 26, 2025",
        "eventCost" : "Free",
        "eventName" : "CareerFair Canada Winnipeg",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in Edmonton at the RBC Convention Centre on March 26th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/winnipeg-career-fair-and-training-expo-canada-march-26-2025-tickets-995465693687?aff=oddtdtcreator",
        "eventImage" : "/images/events/Mar18 - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 51,
        "eventDate" : "Apr 03, 2025",
        "eventEndDate" : "Apr 03, 2025",
        "eventCost" : "",
        "eventName" : "AI & Tech Networking Event",
        "eventDetails" : "Are you a young professional or entrepreneur passionate about AI and Tech who is looking to network? Hip Haus is hosting an AI & Tech Networking Event for Young Professionals & Entrepreneurs event in Toronto. Join them on April 3rd at Mademoiselle Raw Bar and Grill.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/ai-tech-networking-event-for-young-professionals-entrepreneurs-april-tickets-1245496165559",
        "eventImage" : "/images/events/Mar30 - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 52,
        "eventDate" : "Apr 04, 2025",
        "eventEndDate" : "Apr 04, 2025",
        "eventCost" : "Free",
        "eventName" : "AI Specialists Career Fair",
        "eventDetails" : "Are you interested in exploring career paths in AI? The AI Specialists Club is hosting their monthly virtual career fair to help jobseekers gain valuable insights, network with employers and view a range of career opportunities. Join them virtually on April 4th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/aispecialists-virtual-job-fair-career-expo-event-toronto-yyz-tickets-785841893257?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/Mar30a - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 53,
        "eventDate" : "Apr 04, 2025",
        "eventEndDate" : "Apr 04, 2025",
        "eventCost" : "Free",
        "eventName" : "Flexible Hires Virtual Career Fair ",
        "eventDetails" : "Are you interested in exploring flexible career paths? Participate in this virtual career fair to find the ideal flexible career opportunity that suits your lifestyle while gaining valuable insights, networking with employers and viewing a range of career opportunities. Join them virtually on April 4th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/flexiblehires-virtual-job-fair-career-expo-event-toronto-yyz-tickets-803237463877?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/Mar30b - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 54,
        "eventDate" : "Apr 08, 2025",
        "eventEndDate" : "Apr 08, 2025",
        "eventCost" : "18.06",
        "eventName" : "AI & Tech Networking",
        "eventDetails" : "Looking to make valuable connections and expand your network? This month, Startup Valley is bringing its monthly tech networking event to connect with entrepreneurs and venture capitalists to share ideas and grow your business. Join them on April 8th at Kos Restaurant.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.com/e/ai-tech-networking-toronto-tickets-1263066699499?aff=erelexpmlt",
        "eventImage" : "/images/events/Mar30c - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 55,
        "eventDate" : "Apr 09, 2025",
        "eventEndDate" : "Apr 09, 2025",
        "eventCost" : "Free",
        "eventName" : "CareerFair Canada Waterloo",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in Waterloo/Kitchener at Bingemans Conference Centre on April 9th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/kitchenerwaterloo-career-fair-and-training-expo-canada-april-9-2025-tickets-1087383044959?aff=oddtdtcreator",
        "eventImage" : "/images/events/April 5a - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 56,
        "eventDate" : "Apr 10, 2025",
        "eventEndDate" : "Apr 10, 2025",
        "eventCost" : "Free",
        "eventName" : "CareerFair Canada Vancouver",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in Vancouver at the Pan Pacific on April 10th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/vancouver-career-fair-and-training-expo-canada-april-10-2025-tickets-995460698747?aff=oddtdtcreator",
        "eventImage" : "/images/events/April 5b - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 57,
        "eventDate" : "Apr 15, 2025",
        "eventEndDate" : "Jun 24, 2025",
        "eventCost" : "100",
        "eventName" : "SFU VentureLabs",
        "eventDetails" : "SFU VentureLabs will be hosting a women in tech leadership program in Vancouver that will run from April 15 to June 24, 2025. The program fee covers all six sessions and will dive into educational insights and fun networking opportunities!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/elephant-in-the-room-women-in-tech-leadership-program-tickets-1284706595009?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/April 9 - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 58,
        "eventDate" : "Apr 16, 2025",
        "eventEndDate" : "Apr 16, 2025",
        "eventCost" : "13.57",
        "eventName" : "Stellar Socials",
        "eventDetails" : "Looking to connect with like-minded professionals in the industry? Join Stellar Socials for a networking night in Vancouver at the Paradox Hotel. Whether you're looking to expand your network, explore new opportunities, or simply connect with other enthusiasts, this event is for everyone!",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/vancouver-ai-e-commerce-tech-finance-networking-event-tickets-1302421320229?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/April 9b - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 59,
        "eventDate" : "Apr 17, 2025",
        "eventEndDate" : "Apr 17, 2025",
        "eventCost" : "Free",
        "eventName" : "CareerFair Canada Ottawa",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in Ottawa at Rogers Centre on April 17th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/ottawa-career-fair-and-training-expo-canada-april-17-2025-tickets-1087627014679?aff=oddtdtcreator",
        "eventImage" : "/images/events/April 9c - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 60,
        "eventDate" : "Apr 23, 2025",
        "eventEndDate" : "Apr 23, 2025",
        "eventCost" : "Free",
        "eventName" : "CareerFair Canada Halifax",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in Halifax at the Halifax Convention Centre on April 23rd.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/halifax-career-fair-and-training-expo-canada-april-23-2025-tickets-1057127068519?aff=oddtdtcreator",
        "eventImage" : "/images/events/April 16a - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 61,
        "eventDate" : "Apr 23, 2025",
        "eventEndDate" : "Apr 23, 2025",
        "eventCost" : "11.98",
        "eventName" : "Vancouver Tech & Finance Networking Event",
        "eventDetails" : "Join Sum of Five for an exciting networking night filled with transformative discussions, cutting-edge insights, and networking opportunities tailored to bring your business and career to new heights. This event will be taking place in Vancouver at the Paradox Hotel on April 23rd.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/vancouver-tech-finance-networking-event-tickets-1299657944899?aff=ebdssbdestsearch",
        "eventImage" : "/images/events/April 23 - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 62,
        "eventDate" : "Apr 25, 2025",
        "eventEndDate" : "Apr 25, 2025",
        "eventCost" : "Free",
        "eventName" : "Startup Networking for Entrepreneurs & Professionals",
        "eventDetails" : "Are you an entrepreneur, founder, or professional looking to expand your network and tap into the startup ecosystem? Join No Worker Left Behind on April 25 for a virtual networking event to exchange ideas, discover opportunities, and forge meaningful connections.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/startupnetworking-for-entrepreneurs-professionals-vancouver-tickets-1234475733149?aff=ebdssbdestsearch&keep_tld=1",
        "eventImage" : "/images/events/April 16b - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
    {
        "id" : 63,
        "eventDate" : "Apr 30, 2025",
        "eventEndDate" : "Apr 30, 2025",
        "eventCost" : "Free",
        "eventName" : "CareerFair Canada Ontario",
        "eventDetails" : "Looking to explore jobs or network with employers? Career Fair Canada is hosting a career and training fair in the Peel Region of Ontario at the Pearson Convention Centre on April 30th.",
        "buttonName" : "Learn More",
        "buttonLink" : "https://www.eventbrite.ca/e/peel-regiongta-north-career-fair-and-training-expo-canada-apr-30-2025-tickets-1087345833659?aff=oddtdtcreator",
        "eventImage" : "/images/events/April 16c - Tech Events LinkedIn Post.png",
        "eventImageAlt" : "illustration"
    },
]
