import React from 'react';
import { Link } from 'react-router-dom';
import { BlogsData } from '../../BlogsData.js';

const LatestPostOne = ( props ) => {
    return (
        <div className={`edu-blog-widget${ props.style2 === 'enable' ? '-2' : '' } widget-latest-post ${ props.extraClass || '' }`}>
            <div className="inner">
                <h5 className="widget-title">Latest Post</h5>
                <div className="content latest-post-list">
                    {
                        BlogsData.slice( -3 ).sort((a,b) => new Date(b.date) - new Date(a.date)).map( ( item ) => {
                            return(
                                <div className="latest-post row" key={ item.id }>
                                    <div className="col-4">
                                        <div className="thumbnail">
                                            <Link className="d-block" to={process.env.PUBLIC_URL + `/blogs/${item.link}`}>
                                                <img src={`${process.env.PUBLIC_URL}/images/blogs/${item.image}`} alt="Blog Thumb" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="post-content">
                                            <ul className="blog-meta">
                                                <li>{item.date}</li>
                                            </ul>
                                            <h6 className="title">
                                                <Link className="d-block" to={process.env.PUBLIC_URL + `/blogs/${item.link}`}>{item.title}</Link>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )
                        } )
                    }
                </div>
            </div>
        </div>
    )
}

export default LatestPostOne;