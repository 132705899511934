export const BlogsData = [
    {
        "id": 1,
        "title": "How Machine Learning is Unveiling the Mysteries Hidden Within RNA-Protein Interactions",
        "image": "machine_learning_RNA_protein_interactions_thumb.webp",
        "date": "26 March, 2025",
        "shortDate": "26 Mar",
        "author": ["Hammad Qazi"],
        "categories": ["ML Industry"],
        "link": "blog1"
    },
    {
        "id": 2,
        "title": "When AI Gets Under Your Skin: The Future of Precision Medicine",
        "image": "applications_of_ai_in_precision_medicine_thumb.png",
        "date": "3 April, 2025",
        "shortDate": "3 Apr",
        "author": ["Esha Noor"],
        "categories": ["ML Industry"],
        "link": "blog2"
    },
    {
        "id": 3,
        "title": "How to Break Into AI Industry",
        "image": "breaking_into_ml_industry_thumb.webp",
        "date": "5 April, 2025",
        "shortDate": "5 Apr",
        "author": ["Bao Nguyen"],
        "categories": ["ML Industry"],
        "link": "blog3"
    },
    // {
    //     "id": 4,
    //     "title": "How to Break Into AI Industry",
    //     "image": "post-02.jpg",
    //     "date": "7 January, 2024",
    //     "shortDate": "7 Jan",
    //     "author": ["Bao Nguyen"],
    //     "categories": ["ML Industry"],
    //     "link": "blog1"
    // },
    // {
    //     "id": 5,
    //     "title": "How to Break Into AI Industry",
    //     "image": "post-02.jpg",
    //     "date": "7 January, 2024",
    //     "shortDate": "7 Jan",
    //     "author": ["Bao Nguyen"],
    //     "categories": ["ML Industry"],
    //     "link": "blog1"
    // },
    // {
    //     "id": 6,
    //     "title": "How to Break Into AI Industry",
    //     "image": "post-02.jpg",
    //     "date": "7 January, 2024",
    //     "shortDate": "7 Jan",
    //     "author": ["Bao Nguyen"],
    //     "categories": ["ML Industry"],
    //     "link": "blog1"
    // },
    // {
    //     "id": 7,
    //     "title": "How to Break Into AI Industry",
    //     "image": "post-02.jpg",
    //     "date": "7 January, 2024",
    //     "shortDate": "7 Jan",
    //     "author": ["Bao Nguyen"],
    //     "categories": ["ML Industry"],
    //     "link": "blog1"
    // },
    // {
    //     "id": 8,
    //     "title": "How to Break Into AI Industry",
    //     "image": "post-02.jpg",
    //     "date": "7 January, 2024",
    //     "shortDate": "7 Jan",
    //     "author": ["Bao Nguyen"],
    //     "categories": ["ML Industry"],
    //     "link": "blog1"
    // },
    // {
    //     "id": 9,
    //     "title": "How to Break Into AI Industry",
    //     "image": "post-02.jpg",
    //     "date": "7 January, 2024",
    //     "shortDate": "7 Jan",
    //     "author": ["Bao Nguyen"],
    //     "categories": ["ML Industry"],
    //     "link": "blog1"
    // },
    // {
    //     "id": 10,
    //     "title": "How to Break Into AI Industry",
    //     "image": "post-02.jpg",
    //     "date": "7 January, 2024",
    //     "shortDate": "7 Jan",
    //     "author": ["Bao Nguyen"],
    //     "categories": ["ML Industry"],
    //     "link": "blog1"
    // },
    // {
    //     "id": 11,
    //     "title": "How to Break Into AI Industry",
    //     "image": "post-02.jpg",
    //     "date": "7 January, 2024",
    //     "shortDate": "7 Jan",
    //     "author": ["Bao Nguyen"],
    //     "categories": ["ML Industry"],
    //     "link": "blog1"
    // },
    // {
    //     "id": 12,
    //     "title": "How to Break Into AI Industry",
    //     "image": "post-02.jpg",
    //     "date": "7 January, 2024",
    //     "shortDate": "7 Jan",
    //     "author": ["Bao Nguyen"],
    //     "categories": ["ML Industry"],
    //     "link": "blog1"
    // },
    // {
    //     "id": 13,
    //     "title": "How to Break Into AI Industry",
    //     "image": "post-02.jpg",
    //     "date": "7 January, 2024",
    //     "shortDate": "7 Jan",
    //     "author": ["Bao Nguyen"],
    //     "categories": ["ML Industry"],
    //     "link": "blog1"
    // },
]